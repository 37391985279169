import { graphql, Link, StaticQuery } from "gatsby"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import CustomAccordion from "../components/common/accordian"
import Buttons from "../components/common/button"
import CTA from "../components/common/CTA"
import Frame from "../components/common/frame"
import Hero from "../components/common/Hero/Hero"
import SVGIcon from "../components/common/SVGIcon"
import Navigation from "../components/navigation"
import CheckLinkDomain from "../components/utility/current_domain"
import Slide from "../components/utility/slide_content"
import "../styles/component/faq.scss"
import "../styles/component/pricing-faq.scss"
import "../styles/pages/roadmap.scss"

const WhatsNew = () => {
  return (
    <>
      <StaticQuery
        query={graphql`
          query whatsNew {
            SuperOps {
              pages(where: { title: "product roadmap" }) {
                title
                seo {
                  title
                  description
                  image {
                    url
                  }
                }
                newField {
                  ... on SuperOps_Hero {
                    __typename
                    tag
                    backgroundColor
                    heroImage {
                      url
                    }
                    heroHeading: heading {
                      text
                      html
                    }
                    content {
                      text
                    }
                    secondaryButtonLink
                  }
                  ... on SuperOps_FaqComponent {
                    __typename
                    heading
                    description
                    faqs {
                      question
                      answerrt {
                        html
                      }
                      tagText: linkText
                      tagColor: slug
                    }
                    text
                    link
                  }
                  ... on SuperOps_Block {
                    __typename
                    blockHeading
                    blockDescription
                    blocksIcon {
                      url
                    }
                    blockContent {
                      html
                      text
                    }
                    blockImage {
                      url
                    }
                    linkName
                    linkSlug
                  }
                  ... on SuperOps_CtaFeature {
                    __typename
                    ctaBox {
                      type
                      theme
                      customBackgroundColor
                      heading {
                        html
                      }
                      description {
                        html
                      }
                      primaryButtonText
                      primaryButtonLink
                      secondaryButtonText
                      secondaryButtonLink
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.pages.map(page => {
              const { seo, newField } = page
              const hero = newField[0]
              let navBgColor = hero.backgroundColor
              navBgColor = navBgColor.slice(
                navBgColor.indexOf("#"),
                navBgColor.indexOf("#") + 7
              )

              return (
                <div className="roadmap">
                  <Frame seo={seo} newButton>
                    <header>
                      <Navigation
                        color={navBgColor}
                        page="About us"
                        dskScroll={450}
                        newButton
                      />
                    </header>

                    {newField.map((field, ind) => {
                      switch (field.__typename) {
                        case "SuperOps_Hero": {
                          return (
                            <>
                              <Hero
                                background={"#FFC2C8"}
                                tag={field.tag}
                                heading={field.heroHeading.text}
                                subText={field.content.text}
                                backgroundImage={field.backgroundColor}
                                image={field.heroImage.url}
                                videoURL={field.secondaryButtonLink}
                                maxHeadingWidth="776px"
                                maxDescriptionWidth="290px"
                                marginBottom="-470px"
                                lgLeft={12}
                                lgRight={0}
                                hideImage
                                roadmap
                              />
                            </>
                          )
                        }
                        case "SuperOps_FaqComponent": {
                          return (
                            <>
                              <section className="faq down-to-up-4" id={ind}>
                                <div
                                  style={{
                                    background: `${ind !== 1 &&
                                      ind % 2 === 1 &&
                                      "linear-gradient(180deg, rgba(251, 251, 251, 0.00) 0%, #E2EBF3 30%, #E2EBF3 70%, rgba(251, 251, 251, 0.00) 100%)"}`,
                                  }}
                                >
                                  <Container className="Layout-container">
                                    <Row className="justify-content-between">
                                      <Col lg={3} className="left">
                                        <h3 className="heading fw-bold">
                                          {field.heading}
                                        </h3>
                                        <div className="p16">
                                          <p className="description mb16">
                                            {field.description}
                                          </p>
                                          {field.text && (
                                            <Link
                                              className="d-flex align-items-center link-wrapper"
                                              to={field.link}
                                              target={
                                                CheckLinkDomain(field.link)
                                                  ? "_self"
                                                  : "_blank"
                                              }
                                            >
                                              <p className="p16 link m-0 fw-500">
                                                {field.text}
                                              </p>
                                              <SVGIcon
                                                name="navigation/Path"
                                                className="arrow"
                                              />
                                            </Link>
                                          )}
                                        </div>
                                      </Col>
                                      <Col lg={9} className="right">
                                        <div className="section">
                                          <CustomAccordion items={field.faqs} />
                                        </div>
                                      </Col>
                                    </Row>
                                  </Container>
                                </div>
                              </section>
                            </>
                          )
                        }
                        case "SuperOps_Block": {
                          return (
                            <>
                              <section
                                className="feature-section mt80"
                                style={{
                                  background: `${field.blockDescription}`,
                                }}
                              >
                                <Slide delay={200}>
                                  <Container className="Layout-container">
                                    <Row className="justify-content-end align-items-center">
                                      <Col lg={5}>
                                        <div className="altv3 p18">
                                          <h2 className="heading fw-bold mb24">
                                            {field.blockHeading}
                                          </h2>
                                          <p className="description mb30">
                                            {field.blockContent.text}
                                          </p>
                                          <Link
                                            to={field.linkSlug}
                                            target={
                                              CheckLinkDomain(field.linkSlug)
                                                ? "_self"
                                                : "_blank"
                                            }
                                          >
                                            <Buttons
                                              theme={`primary-new`}
                                              text={field.linkName}
                                            />
                                          </Link>
                                        </div>
                                      </Col>
                                      <Col lg={5}>
                                        <div className="position-relative image-wrapper">
                                          <SVGIcon
                                            name="curveVector"
                                            className="position-absolute curve-arrow d-dsk"
                                          />
                                          <img
                                            src={field.blockImage[0].url}
                                            className="position-absolute image"
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                  </Container>
                                </Slide>
                              </section>
                            </>
                          )
                        }
                        case "SuperOps_CtaFeature": {
                          return (
                            <>
                              <section className="mt80">
                                <CTA
                                  data={[field.ctaBox]}
                                  className="Layout-container"
                                  maxHeadingWidth="400px"
                                  maxDescriptionWidth="400px"
                                  lgLeft={6}
                                  lgRight={4}
                                  rightStyles={{ alignItems: "center" }}
                                  newButton
                                  newDesign
                                />
                              </section>
                            </>
                          )
                        }
                      }
                    })}
                  </Frame>
                </div>
              )
            })}
          </>
        )}
      />
    </>
  )
}

export default WhatsNew
